<!--
 * ======================================
 * 说明： 登录后强制修改初始密码
 * 作者： Silence
 * 文件： modifyPassword.vue
 * 日期： 2023/6/1 21:25
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    title="更新初始登录密码"
    confirmText="保 存"
    width="600px"
    height="340px"
    :show-close="true"
    :is-click-model="false"
    v-model="visible"
  >
    <el-form :model="editForm" ref="editForm" :rules="rule" label-width="80px">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input
              show-password
              v-model="editForm.oldPassword"
            ></el-input> </el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="新密码" prop="password">
            <el-input
              show-password
              v-model="editForm.password"
            ></el-input></el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="确认密码" prop="rPassword">
            <el-input
              show-password
              v-model="editForm.rPassword"
            ></el-input></el-form-item
        ></el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-button type="success" @click="handleConfirm">更新密码</el-button>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
import { clearLocalStorage } from "@/util/storage";
import Cookies from "js-cookie";
export default {
  name: "",
  components: { Dialog },
  props: {},
  data() {
    return {
      visible: false,
      role: "",
      editForm: {
        oldPassword: "",
        password: "",
        rPassword: "",
      },
      rule: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入密码"));
              } else if (value.length < 6) {
                callback(new Error("密码长度不能小于6位"));
              } else {
                if (this.editForm.rPassword !== "") {
                  this.$refs.editForm.validateField("rPassword");
                }
                callback();
              }
            },
          },
        ],
        rPassword: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请再次输入密码"));
              } else if (value !== this.editForm.password) {
                callback(new Error("两次输入密码不一致!"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  打开强制修改密码
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/1 21:27
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    open(role) {
      this.role = role;
      if(window.COMPULSION_EDIT_PASSWORD){
        this.visible = true;
      }
    },

    /**
     *  @actions:  确定修改密码
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/1 21:26
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm() {
      this.$refs["editForm"].validate((vaild) => {
        if (vaild) {
          let url = "/user/teacher_edit_password";
          if (this.role === "student") {
            url = "/student/student_edit_password";
          }
          this.$cloud
            .post(url, this.editForm)
            .then(() => {
              this.visible = false;
              this.$message.success("密码更新成功，请重新登录系统！");
              
              setTimeout(() => {
                Cookies.remove("hankun_cloud_template_token");
                clearLocalStorage();
                this.$cloud.go("/pages/login/login");
              }, 1000);
            })
            .catch((err) => {
              this.$message.error(err);
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
