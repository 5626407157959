<template>
  <div class="bg-white rounded-xl h-full">
    <div
      class="
        max-w-2xl
        mx-auto
        py-16
        px-4
        h-full
        sm:py-8 sm:px-6
        lg:max-w-7xl lg:px-8
      "
    >
      <div class="pb-5 border-b border-gray-200">
        <h3 class="text-lg leading-6 font-medium text-gray-900">我的班级</h3>
        <p class="mt-2 max-w-4xl text-sm text-gray-500">
          进入不同的班级可以对不同班级的学生进行评分，班主任可进入班级进行对应的操作
        </p>
      </div>
      <div
        v-if="classs.length"
        class="
          mt-6
          grid grid-cols-1
          gap-x-8 gap-y-8
          sm:grid-cols-2 sm:gap-y-10
          lg:grid-cols-4
        "
        style="height: calc(100% - 95px); overflow: hidden; overflow-y: auto"
      >

        <div
          class="relative group"
          v-for="(item, index) in classs"
          :key="index"
          @click="handleToScore(item)"
        >
          <div
            class="
              aspect-w-4 aspect-h-3
              rounded-lg
              overflow-hidden
              bg-gray-100
              relative
            "
          >
            <img
              src="~@/assets/class.jpg"
              alt="Payment application dashboard screenshot with transaction table, financial highlights, and main clients on colorful purple background."
              class="object-center object-cover"
            />
            <div
              class="flex items-end opacity-0 p-4 group-hover:opacity-100"
              aria-hidden="true"
            >
              <div
                class="
                  w-full
                  bg-white bg-opacity-75
                  backdrop-filter backdrop-blur
                  py-2
                  px-4
                  rounded-md
                  text-sm
                  font-medium
                  text-gray-900 text-center
                  cursor-pointer
                "
              >
                进入班级
              </div>
            </div>
          </div>
          <div
            class="
              mt-4
              flex
              items-center
              justify-between
              text-base
              font-medium
              text-gray-900
              space-x-8
            "
          >
            <h3>
              <span aria-hidden="true" class="absolute inset-0"></span>
              {{ item.level
              }}{{
                item.name.length > 1
                  ? String(item.name)
                  : "0" + String(item.name)
              }}班
            </h3>
          </div>
          <p class="mt-1 text-sm text-gray-500 inline-block flex">
            <span class="text-left">共:{{ item.count }} 人</span>
          </p>
        </div>
        <!-- More products... -->
      </div>
      <el-empty v-else class="mt-32" description="当前没有评分任务"></el-empty>
    </div>
    <modifyPassword ref="modify"></modifyPassword>
  </div>
</template>
<script>
import modifyPassword from "@/pages/select/modifyPassword.vue";
export default {
  name: "",
  components: { modifyPassword },
  props: {},
  data() {
    return {
      info: {},
      classs: [], // 班级列表
      masterId: "", // 班主任id
    };
  },
  created() {},
  mounted() {
    this.handleLoadClasss();
  },
  methods: {
    // 进入评分页面
    handleToScore(item) {
      this.$cloud.go("/pages/select/score", {
        id: item.id,
        // name: item.name,
        // teacher: this.info.id,
        isMaster: item.isMaster,
        // role:'teacher',
      });
    },

    // 获取当前教师所管理的班级列表
    handleLoadClasss() {
      this.$cloud
        .get("/login/info")
        .then(async (res) => {
          console.log("登录信息：", res);
          if (res.editPassword === "NO") {
            // 强制修改初始密码
            this.$refs["modify"].open("teacher");
          }

          this.info = res;
          let masterRoom = null;
          if (res.masterRoom) {
            this.masterId = res.masterRoom.id;
            masterRoom = {
              id: res.masterRoom.id,
              level: res.masterRoom.level,
              name: res.masterRoom.name,
              count: res.masterRoom.count,
              isMaster: 1,
              subjectId: -1,
              evaluationId: res.masterRoom.evaluationId,
            };
          }
          let subjectRoom = [];
          if (
            res.subjectRoom &&
            typeof res.subjectRoom == "object" &&
            res.subjectRoom.length
          ) {
            res.subjectRoom.forEach((item) => {
              if (
                masterRoom &&
                parseInt(masterRoom.level) === parseInt(item.room.level) &&
                masterRoom.name === item.room.name
              ) {
                return false;
              }
              console.log("Name::", item.room);
              console.log("item::", item);
              subjectRoom.push({
                id: item.room.id,
                level: item.room.level,
                name: item.room.name,
                count: item.room.student_ids
                  ? item.room.student_ids.split(",").length
                  : 0,
                isMaster: 0,
                subjectId: item.subject[0]?.id,
                evaluationId: item.subject[0]?.evaluationId,
              });
            });
          }
          if (masterRoom && subjectRoom.length) {
            this.classs = [masterRoom].concat(subjectRoom);
          } else if (masterRoom && !subjectRoom.length) {
            this.classs = [masterRoom];
          } else if (!masterRoom && subjectRoom.length) {
            this.classs = subjectRoom;
          }

          // await this.getScoreNumbers();    // 不显示评分数
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  获取班级评分数
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/14 23:15
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getScoreNumbers() {
      for (let i in this.classs) {
        await this.$cloud
          .get("/evaluation/student_count", {
            roomId: this.classs[i].id,
            subjectId: this.classs[i].subjectId,
            evaluationId: this.classs[i].evaluationId || 1,
          })
          .then(async (res) => {
            let item = this.classs[i];
            item = Object.assign(item, { ...res });
            this.classs.splice(i, 1, item);
          })
          .catch((err) => {
            this.$message.error(err);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
